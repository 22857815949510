<template>
  <div>
    <v-container>
      <page-title title="Employee">
        <template slot="action">
          <v-btn class="mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mr-n1 mx-1" @click="saveData()" color="primary">Save</v-btn>
        </template>
      </page-title>

      <card-expansion title="General">
        <v-form v-model="formValid" lazy-validation ref="form1">
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.emp_id" :rules="formRules.emp_id" class="" label="Emp ID" placeholder="Emp ID" outlined dense></v-text-field>
              <v-text-field v-model="formData.first_name" :rules="formRules.first_name" class="" label="Firs Name" placeholder="Firs Name" outlined dense></v-text-field>
              <v-text-field v-model="formData.middle_name" class="" label="MIddle Name" placeholder="MIddle Name" outlined dense></v-text-field>
              <v-text-field v-model="formData.last_name" class="" label="Last Name" placeholder="Last name" outlined dense></v-text-field>
              <v-text-field v-model="formData.username" :rules="formRules.username" class="" label="Username" placeholder="Username" outlined dense></v-text-field>
              <v-text-field v-model="formData.full_name" :rules="formRules.full_name" class="" label="Full Name" placeholder="Fullname" outlined dense></v-text-field>
              <v-text-field v-model="formData.emp_no_" :rules="formRules.emp_no_" class="" label="Emp No." placeholder="Emp No." outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.email" :rules="formRules.email" class="" label="Email" placeholder="Email" outlined dense></v-text-field>
              <v-text-field v-model="formData.pos_name_eng" :rules="formRules.pos_name_eng" class="" label="POS Name (en)" placeholder="POS Name (en)" outlined dense></v-text-field>
              <v-text-field v-model="formData.work_location_code" :rules="formRules.work_location_code" class="" label="Work Location Code" placeholder="Work Location Code" outlined dense></v-text-field>
              <v-text-field v-model="formData.spv_parent" :rules="formRules.spv_parent" class="" label="SPV Parent" placeholder="SPV Parent" outlined dense></v-text-field>
              <v-text-field v-model="formData.spv_name" :rules="formRules.spv_name" class="" label="SPV Name" placeholder="SPV Name" outlined dense></v-text-field>
              <v-text-field v-model="formData.spv_path" :rules="formRules.spv_path" class="" label="SPV Path" placeholder="SPV Path" outlined dense></v-text-field>
              <v-select v-model="formData.status" :rules="formRules.status" :items="options.status" class="" label="Status" placeholder="Status" outlined dense></v-select>
            </v-col>
          </v-row>
        </v-form>
      </card-expansion>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      formValid: null,
      formData: {
        emp_id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        username: null,
        full_name: null,
        emp_no_: null,
        email: null,
        pos_name_eng: null,
        work_location_code: null,
        spv_parent: null,
        spv_name: null,
        spv_path: null,
        status: false,
      },
      formRules: {
        emp_id: [v => !!v || 'Emp ID is required'],
        first_name: [v => !!v || 'First Name is required'],
        username: [v => !!v || 'Username is required'],
        full_name: [v => !!v || 'Full Name is required'],
        emp_no_: [v => !!v || 'Emp No. is required'],
        email: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid',],
        pos_name_eng: [v => !!v || 'Post Name (en) is required'],
        work_location_code: [v => !!v || 'Work Location is required'],
        spv_parent: [v => !!v || 'Spv Parent is required'],
        spv_name: [v => !!v || 'Spv Name is required'],
        spv_path: [v => !!v || 'Spv Path is required'],
      },
      options: {
        status: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'In Active',
            value: false,
          }
        ]
      },
    }
  },

  methods: {
    async saveData() {
      this.$refs.form1.validate();

      if (!this.formValid) {
        this.showAlert("error", "Oops.. Please complete the form");
        return;
      }

      let formData = new FormData();

      formData.append('emp_id', this.formData.emp_id);
      formData.append('first_name', this.formData.first_name);
      formData.append('middle_name', this.formData.middle_name);
      formData.append('last_name', this.formData.last_name);
      formData.append('username', this.formData.username);
      formData.append('full_name', this.formData.full_name);
      formData.append('emp_no_', this.formData.emp_no_);
      formData.append('email', this.formData.email);
      formData.append('pos_name_eng', this.formData.pos_name_eng);
      formData.append('work_location_code', this.formData.work_location_code);
      formData.append('spv_parent', this.formData.spv_parent);
      formData.append('spv_name', this.formData.spv_name);
      formData.append('spv_path', this.formData.spv_path);
      formData.append('status', this.formData.status);

      await this.$axios
        .post("employee/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    }
  },

  mounted() {
  }
}
</script>